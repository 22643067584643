import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Form, Row, Col } from 'react-bootstrap';
import { getPurchaseTexts, getReport, submitReport } from '@/api/index';
import { FNum, FDate, FSel, FRadio, Btn, FNote, FTxt } from '@/components/basic/index';
import { FItemGroup, Section, Toast } from '@/components/shared/index';
import ConfirmModal from '@/components/page-parts/ConfirmModal';
import Report from '@/entity/Report';
import style from '@/pages/report/Report.scss';
import { Validator, Rule } from '@/util/validate';

const GoodReportForm = ({ studentId, targetReportId, prevReportId, onBack }) => {
  const history = useHistory();
  const [report, setReport] = useState(new Report());
  const [errors, setErrors] = useState({});
  const [beforeReport, setBeforeReport] = useState(new Report());
  const [purchaseTexts, setPurchaseTexts] = useState([]);
  const [confirmModal, setConfirmModal] = useState({
    show: false,
    title: '',
    content: '',
    type: '',
    buttonTitle: '',
  });

  const hours = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22].map((r) => {
    return { value: r, label: `${r}時` };
  });
  const minutes = [0, 1, 2, 3, 4, 5].map((r) => {
    return { value: r * 10, label: `${r * 10}分` };
  });
  const points = ['良い', '普通', '悪い'].map((r) => {
    return { value: r, label: r };
  });
  // const pointRates = ['0', '10', '20', '30', '40', '50', '60', '70', '80', '90', '100'].map((r) => {
  //   return { value: r, label: r + '%' };
  // });

  useEffect(async () => {
    const d = await getPurchaseTexts(studentId);
    setPurchaseTexts(
      d.map((r) => {
        r['textbookName'] = `${r.textName} ${r.gradeName} ${r.subjectName}`;
        return r;
      })
    );
  }, []);

  const setAndAdjustReport = (reportData) => {
    if (!reportData.isTurnIn) {
      reportData.mergeQuestionnaires();
    }
    setReport(reportData);
  };

  const getBeforePage = (row) => {
    const hit = beforeReport.reportProgress.find((r) => row.code === r.code);
    if (!hit) return '';
    return `${'\r\n'}　(前回は${hit.page}まで)`;
  };

  const handleShowConfirm = (type, title, content, buttonTitle) =>
    setConfirmModal({
      ...confirmModal,
      show: true,
      title: title,
      content: content,
      type: type,
      buttonTitle: buttonTitle,
    });
  const handleCloseConfirm = () => setConfirmModal({ ...confirmModal, show: false, title: '', content: '', type: '' });

  const onSubmitReport = async () => {
    handleCloseConfirm();
    report.isApproval = true;
    await submitReport(studentId, report.id, report);
    history.goBack();
    Toast.success('指導報告書を承認しました。');
  };

  useEffect(async () => {
    if (studentId === null || targetReportId === null) return;
    const report = await getReport(studentId, targetReportId);
    setAndAdjustReport(report);
    setBeforeReport(prevReportId ? await getReport(studentId, prevReportId) : new Report());
  }, [targetReportId, prevReportId]);

  const toTopPos = () => setTimeout(() => window.scrollTo(0, 0), 100);

  const onSave = async () => {
    if (validate()) {
      toTopPos();
      Toast.error('入力エラーがあります。');
      return;
    }
    handleShowConfirm('submit', '承認確認', '入力した内容で承認します。よろしいですか？', '承認する');
  };

  const validate = () => {
    const validate = new Validator(report);
    validate.exec('familyEvaluation', '指導内容', [Rule.require()]);
    _.forEach(_.range(report.familyQuestionnaires.inquiries.length), (i) => {
      validate.exec(`familyQuestionnaires.inquiries[${i}].answer`, 'アンケート', [Rule.require()]);
    });
    setErrors(validate.getErrors());
    return validate.inValid();
  };

  return (
    <Form noValidate style={{ marginTop: '1rem' }}>
      <Section title="担当した先生" first>
        <div className={style.teacherName}>
          {report.teacher.familyName} {report.teacher.firstName}
        </div>
      </Section>
      <Section title="指導について" first>
        <FItemGroup label="指導日時" full={false} className={style.spaceB}>
          <Col xs={5} style={{ paddingRight: 0 }}>
            <FDate value={report.date} />
          </Col>
          <Col xs={3} style={{ paddingRight: 0 }}>
            <FSel options={hours} value={report.hour} />
          </Col>
          <Col xs={3} style={{ paddingRight: 0 }}>
            <FSel options={minutes} value={report.minute} />
          </Col>
        </FItemGroup>
        <FItemGroup label="指導時間" full={false} className={style.spaceB}>
          <Col xs={5}>
            <FNum suffix="分" value={report.time} />
          </Col>
        </FItemGroup>
      </Section>
      <Section title="使用テキスト">
        {report.reportProgress.map((r, i) => {
          return (
            <React.Fragment key={i.toString()}>
              <FItemGroup
                label={r.id ? `${r.textName} ${r.gradeName} ${r.subjectName} ${getBeforePage(r)}` : '使用テキスト'}
                full={false}
                paths={[`reportProgress[${i}].code`]}
              >
                <Row style={{ marginRight: 'none', paddingRight: '0px' }}>
                  {r.id ? (
                    <>
                      {!report.isApproval && (
                        <>
                          <Col xs={10} style={{ marginBottom: '0.5rem', paddingRight: '0px' }}></Col>
                          <Col
                            xs={2}
                            style={{ textAlign: 'right', marginRight: '0px', padding: '0px', marginTop: '-30px' }}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Col xs={10} style={{ paddingRight: '0px' }}>
                        {purchaseTexts.length > 0 && (
                          <>
                            <FSel
                              options={purchaseTexts}
                              labelField="textbookName"
                              valueField="textbookName"
                              value={`${r.textName} ${r.gradeName} ${r.subjectName}`}
                              change={(e, row) => {
                                report.reportProgress[i].code = e.target.value;
                                report.reportProgress[i].textName = row.textName;
                                report.reportProgress[i].gradeName = row.gradeName;
                                report.reportProgress[i].subjectName = row.subjectName;
                                setReport({ ...report, reportProgress: report.reportProgress });
                              }}
                              disabled={true}
                            />
                          </>
                        )}
                        {purchaseTexts.length === 0 && (
                          <>
                            <FTxt
                              change={(e, row) => {
                                report.reportProgress[i].code = '';
                                report.reportProgress[i].textName = e.target.value;
                                report.reportProgress[i].gradeName = '';
                                report.reportProgress[i].subjectName = '';
                                setReport({ ...report, reportProgress: report.reportProgress });
                              }}
                              value={`${r.textName}`}
                              disabled={true}
                            ></FTxt>
                          </>
                        )}
                      </Col>
                      <Col xs={2} style={{ textAlign: 'right', marginRight: '0px', padding: '0px' }} />
                    </>
                  )}
                </Row>
              </FItemGroup>
              {window.env.report.page ? (
                <FItemGroup
                  label="ページ"
                  full={false}
                  style={{ marginLeft: '1rem' }}
                  errors={errors}
                  paths={[`reportProgress[${i}].page`, `reportProgress[${i}].maxPage`]}
                >
                  <Row>
                    <Col xs={5}>
                      <FNum
                        suffix="Ｐ"
                        value={r.page}
                        change={(e) => {
                          report.reportProgress[i].page = e.target.value;
                          setReport({ ...report, reportProgress: report.reportProgress });
                        }}
                        disabled={true}
                      />
                    </Col>
                    <Col xs={1} className={style.slashSeparate}>
                      ／
                    </Col>
                    <Col xs={5}>
                      <FNum
                        suffix="Ｐ"
                        value={r.maxPage}
                        change={(e) => {
                          report.reportProgress[i].maxPage = e.target.value;
                          setReport({ ...report, reportProgress: report.reportProgress });
                        }}
                        disabled={true}
                      />
                    </Col>
                  </Row>
                </FItemGroup>
              ) : (
                <></>
              )}
              {window.env.report.learningUnit ? (
                <FItemGroup
                  label={'単元単位'}
                  style={{ marginLeft: '1rem' }}
                  errors={errors}
                  paths={[`reportProgress[${i}].learningUnit`]}
                >
                  <FNote
                    rows={5}
                    value={r.learningUnit}
                    change={(e) => {
                      report.reportProgress[i].learningUnit = e.target.value;
                      setReport({ ...report, reportProgress: report.reportProgress });
                    }}
                    disabled={true}
                  />
                </FItemGroup>
              ) : (
                <></>
              )}
              <FItemGroup label="生徒の理解度" className={style.spaceB} style={{ marginLeft: '1rem' }}>
                <Col xs={3}>{report.reportProgress[i].points}%</Col>
              </FItemGroup>
            </React.Fragment>
          );
        })}
        <FItemGroup label="指導内容" errors={errors} paths={['content']}>
          <FNote
            rows={5}
            value={report.content}
            change={(e) => setReport({ ...report, content: e.target.value })}
            disabled={true}
          />
        </FItemGroup>
      </Section>
      <Section title="評価について">
        <FItemGroup label="やる気・モチベーション">
          <div style={{ marginLeft: '1rem' }}>
            <FRadio options={points} gName="teacher-point" value={report.teacherEvaluation} />
          </div>
        </FItemGroup>
      </Section>
      <Section title="料金について">
        <FItemGroup label="指導料金" className={style.spaceB}>
          <Col>
            <FNum suffix="円" value={report.fee} />
          </Col>
        </FItemGroup>
        <FItemGroup label="交通費">
          <Col>
            <FNum suffix="円" value={report.travelCost} />
          </Col>
        </FItemGroup>
      </Section>
      <Section title="次回予定">
        <FItemGroup label="日時" full={false} className={style.spaceB}>
          <Col xs={5} style={{ paddingRight: 0 }}>
            <FDate value={report.nextDate} />
          </Col>
          <Col xs={3} style={{ paddingRight: 0 }}>
            <FSel options={hours} value={report.nextHour} />
          </Col>
          <Col xs={3} style={{ paddingRight: 0 }}>
            <FSel options={minutes} value={report.nextMinute} />
          </Col>
        </FItemGroup>
        <FItemGroup label="指示内容">
          <Col>
            <FNote rows={5} value={report.nextInstructions} />
          </Col>
        </FItemGroup>
      </Section>
      <Section title="ご家庭・評価">
        <FItemGroup label="指導内容" errors={errors} paths={['familyEvaluation']}>
          <div style={{ marginLeft: '1rem' }}>
            <FRadio
              options={points}
              gName="point"
              value={report.familyEvaluation}
              change={(e) => setReport({ ...report, familyEvaluation: e.target.value })}
              disabled={report.isApproval}
            />
          </div>
        </FItemGroup>
      </Section>
      <Section title="アンケート">
        {report.familyQuestionnaires.inquiries &&
          report.familyQuestionnaires.inquiries.map((r, i) => {
            return (
              <React.Fragment key={i}>
                <FItemGroup
                  label={r.inquiry}
                  className={style.spaceB}
                  errors={errors}
                  paths={[`familyQuestionnaires.inquiries[${i}].answer`]}
                >
                  <div style={{ marginLeft: '1rem' }}>
                    {report.isApproval ? (
                      r.answer ? (
                        r.answer
                      ) : (
                        '未回答'
                      )
                    ) : (
                      <FRadio
                        options={r.answers.map((rr) => {
                          return {
                            value: rr,
                            label: rr,
                          };
                        })}
                        value={r.answer}
                        gName={`inquiry${i}`}
                        change={(e) => {
                          report.familyQuestionnaires.inquiries[i].answer = e.target.value;
                          setReport({ ...report, familyQuestionnaires: report.familyQuestionnaires });
                        }}
                        disabled={report.isApproval}
                      />
                    )}
                  </div>
                </FItemGroup>
              </React.Fragment>
            );
          })}
      </Section>
      <Section title="コメント">
        <FItemGroup label="先生からのコメント">
          <Col>
            <FNote rows={5} value={report.teacherToFamilyComment} disabled={true} />
          </Col>
        </FItemGroup>
        <FItemGroup label="先生へのコメント">
          <Col>
            <FNote
              rows={5}
              value={report.familyToTeacherComment}
              disabled={report.isApproval}
              change={(e) => setReport({ ...report, familyToTeacherComment: e.target.value })}
            />
          </Col>
        </FItemGroup>
        <FItemGroup
          label={
            'グッドに相談があれば（任意）\r\n※先生には通知されません(確認に時間をいただくため、お急ぎの場合は本部へご連絡下さい)'
          }
        >
          <Col>
            <FNote
              rows={5}
              value={report.familyToHeadquartersComment}
              change={(e) => setReport({ ...report, familyToHeadquartersComment: e.target.value })}
              disabled={report.isApproval}
            />
          </Col>
        </FItemGroup>
      </Section>
      <Row style={{ textAlign: 'right', marginTop: '2rem', marginBottom: '2rem' }}>
        {report.isApproval ? (
          <Btn click={onBack} color="secondary" outline children="戻る" prefixIcon="faAnglesLeft" />
        ) : (
          <Col>
            <Btn style={{ marginBottom: '1rem' }} click={onSave} prefixIcon="faShareFromSquare" children="承認する" />
            <Btn click={onBack} color="secondary" outline children="戻る" prefixIcon="faAnglesLeft" />
          </Col>
        )}
      </Row>
      <ConfirmModal
        show={confirmModal.show}
        onClose={handleCloseConfirm}
        onSubmit={async () => await onSubmitReport()}
        title={confirmModal.title}
        content={confirmModal.content}
        footerButtonTitle={confirmModal.buttonTitle}
        showSubmitLoading={true}
      />
    </Form>
  );
};

export default GoodReportForm;
